import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NAV_LINKS } from '../../const/links';
// import Resume from '../../pages/resume/Resume';
import { ClovaOcr } from './clovaOcr/ClovaOcr';
import { Esports } from './esports/Esports';
import { Html5VodLive } from './html5VodLive/Html5VodLive';
import { Insurance } from './insurance/Insurance';
import { Keris } from './keris/Keris';
import { Kiosk } from './kiosk/Kiosk';
import { LineSearch } from './lineSearch/LineSearch';
import { NaverFlash } from './naverFlash/NaverFlash';
import { NaverHtml } from './naverHtml/NaverHtml';
import { OpenbasePOS } from './openbasePOS/OpenbasePOS';
import { OpenbaseSite } from './openbaseSite/OpenbaseSite';
import { RMCPlayer } from './rmcPlayer/RMCPlayer';
import { VliveLive } from './vliveLive/VliveLive';
import { VliveULL } from './vliveULL/VliveULL';
import { Naverland } from './naverland/Naverland';

export const PortFolio = () => {
  const { pfname } = useParams();
  const nav = useNavigate();


  const link = useMemo(() => {
    if (pfname) {
      return Object.values(NAV_LINKS).find((link) => {
        const linkUrl = link.url.replace('/', '');
        return linkUrl.includes(pfname) && linkUrl.replace(pfname, '') === '';
      });
    }
  }, [pfname]);

  useEffect(() => {
    if (!pfname) {
      const recentUrl = Object.values(NAV_LINKS).find((link) => link.url.length > 2);
      recentUrl && nav(recentUrl.url);
    }
  }, [nav, pfname]);



  switch (link) {
    case NAV_LINKS.NAVERLAND:
      return <Naverland link={link} />;
    case NAV_LINKS.INSURANCE:
      return <Insurance link={link} />;
    case NAV_LINKS.CLOVA_OCR:
      return <ClovaOcr link={link} />;
    case NAV_LINKS.VLIVE_LIVE_MULTI:
      return <VliveLive link={link} />;
    case NAV_LINKS.NAVER_ESPORTS_LIVE_PLAYER:
      return <Esports link={link} />;
    case NAV_LINKS.LINE_JP_POTAL:
      return <LineSearch link={link} />;
    case NAV_LINKS.VLIVE_LIVE_ULL:
      return <VliveULL link={link} />;
    case NAV_LINKS.HTML5_VOD_LIVE:
      return <Html5VodLive link={link} />;
    case NAV_LINKS.FLASH_RMC:
      return <RMCPlayer link={link} />;
    case NAV_LINKS.EBOOK_DOWNLOADER:
      return <Keris link={link} />;
    case NAV_LINKS.SIGNAGE_KIOSK:
      return <Kiosk link={link} />;
    case NAV_LINKS.FLASH_ETC:
      return <NaverFlash link={link} />;
    case NAV_LINKS.HTML5_ETC:
      return <NaverHtml link={link} />;
    case NAV_LINKS.OPENBASE:
      return <OpenbaseSite link={link} />;
    case NAV_LINKS.OPENBASE_POS:
      return <OpenbasePOS link={link} />;

    default:
      return <div>{link?.text} 포트폴리오 는 작성 전 입니다. </div>;
  }
};
