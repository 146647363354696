import styles from './OpenbasePOS.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';

export const OpenbasePOS = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              OPENBASE 의 회사 사옥 이전을 하면서 사내 카페가 새로 생겼고 <br />
              그때에 사내 카페에서 사용하는 POS 프로그램을 제작 하였습니다. <br />
              POS 에서 결제하는 내용은 모두 실시간으로 조회가 가능하도록 연동 되었고 <br />
              현금 결제 기능은 구현하지 않았습니다.
            </p>
          </dd>
        </dl>
      </section>
    </div>
  );
};
