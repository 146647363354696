import styles from './LineSearch.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';
import img_img1 from './img/img1.jpg';
import img_img2 from './img/img2.jpg';
import img_img3 from './img/img3.jpg';
import img_img4 from './img/img4.jpg';

import img_shopping1 from './img/shopping1.jpg';
import img_shopping2 from './img/shopping2.jpg';
import img_shopping3 from './img/shopping3.jpg';
import img_shopping4 from './img/shopping4.jpg';
import img_shopping5 from './img/shopping5.jpg';

import img_dic1 from './img/dic1.jpg';
import img_dic2 from './img/dic2.jpg';

import img_video1 from './img/video1.jpg';
import img_video2 from './img/video2.jpg';

import img_sticker1 from './img/sticker1.jpg';

import img_expert1 from './img/expert1.jpg';
import img_expert2 from './img/expert2.jpg';

import img_news1 from './img/news1.jpg';
import img_news2 from './img/news2.jpg';

export const LineSearch = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              일본에서 사용하는 라인의 포털 검색 부분에서
              <br />
              이미지 , 쇼핑 , 사전 , 비디오 , 스티커 , expert , 뉴스 탭 부분의 작업을 진행 하였습니다.
            </p>
          </dd>
        </dl>
      </section>

      <h2>이미지 검색</h2>
      <img className={styles.mobileImg} src={img_img1} alt={'이미지 검색'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={img_img2} alt={'이미지 검색'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={img_img3} alt={'이미지 검색'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={img_img4} alt={'이미지 검색'} loading="lazy" decoding="async" />

      <h2>쇼핑 검색</h2>
      <img className={styles.mobileImg} src={img_shopping1} alt={'쇼핑 검색'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={img_shopping2} alt={'쇼핑 검색'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={img_shopping3} alt={'쇼핑 검색'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={img_shopping4} alt={'쇼핑 검색'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={img_shopping5} alt={'쇼핑 검색'} loading="lazy" decoding="async" />

      <h2>사전 검색</h2>
      <img className={styles.mobileImg} src={img_dic1} alt={'사전 검색'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={img_dic2} alt={'사전 검색'} loading="lazy" decoding="async" />

      <h2>비디오 검색</h2>
      <img className={styles.mobileImg} src={img_video1} alt={'비디오 검색'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={img_video2} alt={'비디오 검색'} loading="lazy" decoding="async" />

      <h2>스티커 검색</h2>
      <img className={styles.mobileImg} src={img_sticker1} alt={'스티커 검색'} loading="lazy" decoding="async" />

      <h2>expert 검색</h2>
      <img className={styles.mobileImg} src={img_expert1} alt={'expert 검색'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={img_expert2} alt={'expert 검색'} loading="lazy" decoding="async" />

      <h2>뉴스 검색</h2>
      <img className={styles.mobileImg} src={img_news1} alt={'뉴스 검색'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={img_news2} alt={'뉴스 검색'} loading="lazy" decoding="async" />
    </div>
  );
};
