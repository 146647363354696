import styles from './NaverHtml.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';
import image1 from './img/img1.jpg';
import image2 from './img/img2.jpg';

export const NaverHtml = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              <h3>네이버 서비스의 html , css 신규 작업</h3>
              <ul>
                <li>게임문학상 (게임 어워드)</li>
                <li>me2.do (단축 url)</li>
              </ul>
              <h3>네이버 서비스의 html , css 유지 보수 작업</h3>
              <ul>
                <li>키친</li>
                <li>카페</li>
                <li>오픈캐스트</li>
                <li>앱스토어</li>
                <li>앱팩토리</li>
                <li>기타 프로모션 페이지 등..</li>
              </ul>
              위 서비스들의 html, css 부분을 신규 작업 또는 서스테이닝 하였습니다.
            </p>
          </dd>
        </dl>
      </section>

      <h2>me2.do</h2>
      <img className={styles.mobileImg} src={image1} alt={'me2.do'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={image2} alt={'me2.do'} loading="lazy" decoding="async" />
    </div>
  );
};
