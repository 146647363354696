import { Link, Outlet, useParams } from 'react-router-dom';
import { NAV_LINKS, T_LinkItem } from '../../const/links';
import styles from './Nav.module.scss';
import homeImg from './home.png';
import { useCallback, useEffect, useRef, useState } from 'react';

export const Nav = () => {
  const { pfname } = useParams();

  const refNav = useRef<HTMLElement>(null);
  const [navWidth, setNavWidth] = useState(0);
  const refNavItemsSize = useRef<DOMRect[]>([]);
  const [navItemPosAfterRotate, setNavItemPosAfterRotate] = useState<number[]>([]);
  const [angle, setAngle] = useState(0);
  const [navHeight, setNavHeight] = useState(0);
  const [homeImgSize, setHomeImgSize] = useState(100);
  const refMaxHeight = useRef(0);
  const refIsBreak = useRef(false);
  const refMoreArea = useRef(0);
  const refNavWidth = useRef(0);
  const refInterval = useRef<any>(0);

  useEffect(() => {
    refInterval.current = setInterval(() => {
      if (refNav.current) {
        const newWidth = refNav.current.getBoundingClientRect().width;
        if (refNavWidth.current !== newWidth) {
          setNavWidth(newWidth);
          refNavWidth.current = newWidth;
        }
      }
    }, 10);
    return () => {
      clearInterval(refInterval.current);
    };
  }, []);

  const saveNavItemsSize = useCallback(() => {
    if (refNav.current && refNavItemsSize.current.length === 0) {
      const liNodes = refNav.current.querySelectorAll('li');

      liNodes.forEach((li) => {
        refNavItemsSize.current.push(li.getBoundingClientRect());
      });
    }
  }, []);

  useEffect(() => {
    saveNavItemsSize();
  }, [saveNavItemsSize]);

  useEffect(() => {
    let angle = 90;
    let pos: number[] = [];
    refIsBreak.current = false;
    let minusAngle = 1;
    while (angle > 0) {
      const newAngle = angle - minusAngle;
      const newPos: number[] = [];
      const rad = (newAngle / 360) * Math.PI * 2;
      const sin = Math.sin(rad);
      refMaxHeight.current = 0;
      refMoreArea.current = navWidth;
      const afterRotateWidth = refNavItemsSize.current[0].height / sin;
      const afterRotateWidth_left = refNavItemsSize.current[0].height * sin; // 0/0 을 기준으로 돌아서 왼쪽으로 튀어 나오는 크기

      refNavItemsSize.current.forEach((item, i) => {
        const leftEnd = afterRotateWidth_left + i * afterRotateWidth;
        newPos[i] = leftEnd;

        const newHeight = item.width * Math.sin(rad) + item.height * Math.cos(rad);
        if (newHeight > refMaxHeight.current) refMaxHeight.current = newHeight;

        const rightEnd = leftEnd + Math.cos(rad) * item.width;
        if (rightEnd > navWidth) {
          refIsBreak.current = true;
          return;
        } else {
          if (navWidth - rightEnd < refMoreArea.current) {
            refMoreArea.current = navWidth - rightEnd;
          }
        }
      });

      if (!refIsBreak.current) {
        const ifVerWidth = refNavItemsSize.current.reduce((prev, curr) => prev + curr.height, 0);
        let moreRatio = refMoreArea.current / (navWidth - ifVerWidth);
        if (moreRatio > 0.5) moreRatio = moreRatio * 10;
        minusAngle = Math.max(0.001, moreRatio);
        angle = newAngle;
        pos = newPos;
      } else {
        break;
      }
    }

    setNavItemPosAfterRotate(pos);
    setAngle(angle);
    setNavHeight(refMaxHeight.current);

    const resume = document.querySelector('#resume') as HTMLIFrameElement;
    if (resume) {
      resume.style.height = `calc( 100% - ${refMaxHeight.current}px )`;
    }
  }, [navWidth]);

  useEffect(() => {
    // home 이미지의 사이즈 계산
    const itemH = refNavItemsSize.current[0].height;
    const rad = (angle / 360) * Math.PI * 2;
    // 세로변
    const height = navHeight - itemH * Math.cos(rad);
    const long = height / Math.sin(rad);
    const width = long * Math.cos(rad);
    const a_dash = long * (height / (height + width));
    const imgSize = a_dash * Math.cos(rad);
    setHomeImgSize(imgSize);
  }, [navItemPosAfterRotate, angle, navHeight]);

  const works = Object.values(NAV_LINKS).reduce<T_LinkItem[]>((prev, curr) => {
    if (curr.skills !== undefined) {
      return prev.concat(curr);
    } else {
      return prev;
    }
  }, []);

  return (
    <>
      <div className={styles.wrap}>
        {/* <Home></Home> */}

        <nav className={styles.nav} ref={refNav} style={{ height: navHeight }}>
          <Link className={styles.home} to={NAV_LINKS.HOME.url}>
            <img src={homeImg} alt={'ocr'} style={{ width: `${homeImgSize}px` }} />
          </Link>
          <ul>
            {works.map((link, i) => {
              if (link.skills) {
                return (
                  <li
                    key={i}
                    className={link.url.replace('/', '') === pfname ? styles.on : ''}
                    style={{ transform: ` translate(${navItemPosAfterRotate[i]}px) rotate(${angle}deg)` }}
                  >
                    <Link to={link.url}>
                      {link.text}
                      {link.period && <span>{link.period}</span>}
                    </Link>
                  </li>
                );
              }

              return '';
            })}
          </ul>
        </nav>
        <Outlet />
      </div>
    </>
  );
};
