import styles from './Work.module.scss';

export const Work = (props: { period: string | undefined; tools: string | undefined }) => {
  const { period, tools } = props;

  const ToolDom = (prop: { tools: string }) => {
    if (tools?.indexOf(',') === -1) {
      return <>{tools}</>;
    } else {
      const ts = tools?.split(',');

      return (
        <ul className={styles.tools}>
          {ts?.map((t, i) => (
            <li key={i}>{t}</li>
          ))}
        </ul>
      );
    }
  };

  return (
    <div className={styles.summ}>
      {period && (
        <dl className={styles.period}>
          <dt>작업 기간</dt>
          <dd>{period}</dd>
        </dl>
      )}
      {tools && (
        <dl className={styles.tools}>
          <dt>사용 언어</dt>
          <dd>
            <ToolDom tools={tools}></ToolDom>
          </dd>
        </dl>
      )}
    </div>
  );
};
