import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Nav } from './components/nav/Nav';
import { PortFolio } from './components/portfolio/PortFolio';
import './global.scss';

function App() {
  return (
    <BrowserRouter basename={process.env.REACT_APP_URL}>
      <Routes>
        <Route path="/" element={<Nav />}>
          <Route path="/" element={<PortFolio />} />
          <Route path="/:pfname" element={<PortFolio />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
