import image1 from './img/img1.jpg';
import image2 from './img/img2.jpg';
import image3 from './img/img3.jpg';
import image4 from './img/img4.jpg';
import image5 from './img/img5.jpg';
import styles from './ClovaOcr.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';

export const ClovaOcr = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              ocr 기술을 활용하여
              <br /> 문서에서 텍스트 를 읽는것 ,
              <br /> 의미를 뽑아 낼수있도록 하는 Template OCR ,
              <br /> 추론을 하여 데이터를 뽑아내는 Document OCR 의 기존 기능 들에서 더 나아가
              <br /> 각 상황에 맞게 커스텀이 가능한 추론 Document OCR 을 제공하기 위한 기능을 구현하는 프로젝트에서
              <br /> FE 부분 작업 진행 하였습니다.
              <br /> 박스 그리기 , 이동, 병합 , 텍스트 드래그 드랍 및 차트 구현 의 기능이 개발 되었습니다.
              <br /> 인퍼런스가 완료되면 표본이 될 이미지들에 의미를 부여하고
              <br /> 나머지 이미지들을 이용하여 학습을 시키고 데모를 볼수있는 프로젝트 입니다.
            </p>
          </dd>
        </dl>
      </section>
      <img src={image1} alt={'ocr'} loading="lazy" decoding="async" />
      <img src={image2} alt={'ocr'} loading="lazy" decoding="async" />
      <img src={image3} alt={'ocr'} loading="lazy" decoding="async" />
      <img src={image4} alt={'ocr'} loading="lazy" decoding="async" />
      <img src={image5} alt={'ocr'} loading="lazy" decoding="async" />
    </div>
  );
};
