import styles from './VliveULL.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';
import image1 from './img/img1.jpg';

export const VliveULL = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              vlive 의 라이브 서비스에서 ULL (Ultra Low Latency) 작업을 진행 하였습니다. <br />
              기존의 라이브로 많이 사용이 되던 hls 의 서비스는 그대로 두고 ULL 옵션을 키면 <br />
              google 의 프로토콜인 dash 를 사용하여 구현 하였습니다. <br />
              기존 라이브의 delay 는 10초 내외 인것에 비하여 1~3초대의 delay 의 초저지연 라이브를 개발 하였습니다.
              <img src={image1} alt={'vlive'} loading="lazy" decoding="async" />
            </p>
          </dd>
        </dl>
      </section>
    </div>
  );
};
