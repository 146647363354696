import image1 from './img/img1.png';
import image2 from './img/img2.png';
import image3 from './img/img3.png';
import image4 from './img/img4.png';
import image5 from './img/img5.png';
import image6 from './img/img6.png';
import image7 from './img/img7.png';
import styles from './Insurance.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';

export const Insurance = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              실비 보험 청구를 손쉽게 할수있는 방법을 제공해주는 서비스 입니다. <br />
              사용자가 서류를 사진찍어 청구 할수도 있고 , 제휴된 업체인경우 사진 없이 청구도 가능합니다. <br />
              이미 등록되어있는 보험사 , 계좌 번호를 이용하여 간편하게 청구 할수있고 <br />
              여러개의 보험사를 동시에 청구 할수도있습니다. <br />
            </p>
          </dd>
        </dl>
      </section>
      <img className={styles.mobileImg} src={image1} alt={'실비 보험 청구'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={image2} alt={'실비 보험 청구'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={image3} alt={'실비 보험 청구'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={image4} alt={'실비 보험 청구'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={image5} alt={'실비 보험 청구'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={image6} alt={'실비 보험 청구'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={image7} alt={'실비 보험 청구'} loading="lazy" decoding="async" />
    </div>
  );
};
