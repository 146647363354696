import styles from './Keris.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';
import image1 from './img/img1.jpg';
import image2 from './img/img2.jpg';

export const Keris = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              온라인 으로 교과서를 다운로드 할수있는 서비스로 <br />
              air 어플리케이션을 설치하여 <br />
              학생의 부모님이 교과서를 다운로드 할수있도록 해주는 프로젝트입니다. <br />
              설치 과정에 문제가 있거나 잘 안되는 부모님들이 많아 <br />
              실제 고객 문의를 유선상으로 진행하면서 가이드를 해주기도한 프로젝트 였습니다.
            </p>
          </dd>
        </dl>
      </section>

      <img src={image1} alt={'Keris'} loading="lazy" decoding="async" />
      <img src={image2} alt={'Keris'} loading="lazy" decoding="async" />
    </div>
  );
};
