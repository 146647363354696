import styles from './RMCPlayer.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';
import image1 from './img/img1.jpg';

export const RMCPlayer = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              NAVER 의 RMC(Ready Made Contents) 플레이어 서비스로서 <br />
              pd , hls , rtmp , p2p 의 방식을 사용하는 동영상 플레이어 입니다. <br />
              플래시로 제작 되었고 <br />
              특수 기능으로 멀티 트랙 , vr 기능, vr 광고 및 전광고 , 후광고 , 중간광고, 팝업 광고 , 오버레이 광고 등이
              적용되었으며
              <br />
              스킨만 13가지 정도로 네이버의 UGC 를 제외한 거의 모든 플레이어를 담당 하였습니다.
              <img src={image1} alt={'RMC'} loading="lazy" decoding="async" />
            </p>
          </dd>
        </dl>
      </section>
    </div>
  );
};
