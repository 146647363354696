import styles from './Html5VodLive.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';
import image1 from './img/img1.jpg';

export const Html5VodLive = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              하나의 코어 를 가지고 여러 서비스에 적용 될수있는 html5 플레이어 개발
              <ul>
                <li>linetv live</li>
                <li>livecast live</li>
                <li>livecast vod</li>
                <li>navertv live</li>
                <li>sports live</li>
                <li>tvcast live</li>
                <li>vlive live</li>
              </ul>
              위 서비스에 적용
            </p>
          </dd>
        </dl>
      </section>

      <img src={image1} alt={'ocr'} loading="lazy" decoding="async" />
    </div>
  );
};
