import styles from './VliveLive.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';
import image1 from './img/img1.jpg';

export const VliveLive = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              vLive 플레이어의 UI 개편 및 <br />
              멀티뷰 기능 작업으로, <br />
              사용자가 여러개의 카메라중 하나를 골라서 볼수있는 기능의 작업 을 진행 하였습니다.
              <img src={image1} alt={'vLive'} loading="lazy" decoding="async" />
            </p>
          </dd>
        </dl>
      </section>
    </div>
  );
};
