import styles from './OpenbaseSite.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';
// import image1 from './img/img1.jpg';
// import image2 from './img/img2.jpg';
// import image3 from './img/img3.jpg';
// import image4 from './img/img4.jpg';

export const OpenbaseSite = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              오픈베이스 회사의 홈페이지 및 자회사인 <br />
              nanobase , SPSS 회사의 홈페이지의 유지보수 및 신규 제작 업무를 하였습니다. <br />
              서버는 주로 리눅스 cent os 를 사용하고 sql 은 mysql 을 사용 , backend 는 php 를 사용 하였습니다. <br />
              front 는 html , css 를 사용 하기도 하고 flash 를 사용 하기도 하였습니다. <br />위 작업은 모두 디자인 빼고는 혼자
              진행 하였습니다.
            </p>
          </dd>
        </dl>
      </section>
    </div>
  );
};
