import image1 from './img/imgMo1.jpg';
import image2 from './img/imgMo2.jpg';
import image3 from './img/imgPc1.jpg';

import styles from './Esports.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';

export const Esports = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              NAVER GAME의 카테고리중의 하나인 e-sports 의 출시에 맞춰서
              <br /> 모바일 , pc 에 광고 기능이 포함된 라이브 플레이어를 개발 하였습니다.
            </p>
          </dd>
        </dl>
      </section>

      <h2>mobile</h2>
      <img className={styles.mobileImg} src={image1} alt={'ocr'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={image2} alt={'ocr'} loading="lazy" decoding="async" />
      <h2>PC</h2>
      <img src={image3} alt={'ocr'} loading="lazy" decoding="async" />
    </div>
  );
};
