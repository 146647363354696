import styles from './NaverFlash.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';
import image1 from './img/img1.jpg';

export const NaverFlash = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              <h3>네이버 flash 신규 작업</h3>
              <ul>
                <li>콩 배너 모션 작업</li>
                <li>해피빈 우리마을 학교 도서관 플래시 지도 작업</li>
                <li>굿바이 ie6 동영상 플레이어</li>
                <li>해피빈 개편 메인 레이어</li>
                <li>네이버 앱 홍보 프로모션 풀 플래시 사이트</li>
                <li>네이버 포토갤러리 이미지 뷰어</li>
              </ul>
              <h3>네이버 flash 유지보수 작업</h3>
              <ul>
                <li>네이버 스토리</li>
                <li>어둠속의 대화</li>
              </ul>
              위와 같은 네이버의 다수의 플래시 작업 을 진행 하였습니다.
            </p>
          </dd>
        </dl>
      </section>

      <h2>우리학교 마을 도서관 지도 작업</h2>
      <img className={styles.mobileImg} src={image1} alt={'우리학교 마을 도서관 지도 작업'} loading="lazy" decoding="async" />
    </div>
  );
};
