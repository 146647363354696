import image1 from './img/img1.png';
import styles from './Naverland.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';

export const Naverland = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              네이버 부동산 서비스의 뉴스탭 영역 개편 업무 입니다. <br />
              아직 배포 전이라 작업 상세 이미지는 첨부 안되는점 양해 부탁 드립니다. <br />
            </p>
          </dd>
        </dl>
      </section>

      <img src={image1} alt={'네이버 부동산 서비스'} loading="lazy" decoding="async" />
    </div>
  );
};
