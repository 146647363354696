import styles from './Kiosk.module.scss';
import { Work } from '../common/Work';
import { T_LinkItem } from '../../../const/links';
import image1 from './img/img1.jpg';
import image2 from './img/img2.jpg';
import image3 from './img/img3.jpg';
import image4 from './img/img4.jpg';

export const Kiosk = (props: { link: T_LinkItem }) => {
  const { link } = props;
  return (
    <div className={styles.contents}>
      <h1>{link.text}</h1>

      <Work period={link.period} tools={link.skills}></Work>

      <section className={styles.project_desc}>
        <dl>
          <dt>프로젝트 설명</dt>
          <dd>
            <p>
              <ul>
                <li>라인 사용자 1억명 달성 기원 미디어 스크린 및 배너 작업</li>
                <li>GS 칼텍스 주유기 실급검 ,날씨 , 주유정보 미디어 스크린 작업</li>
                <li>지하철 2호선 , 신분당선 플랫폼에 날씨 , 실급검 , meToday 정보 노출 하는 미디어 스크린 작업</li>
                <li>그린팩토리 1층 미디어월 사이니지 컨텐츠</li>
                <li>그린팩토리 지하 식당 오늘의 메뉴 컨텐츠</li>
                <li>국립극단 로비에 들어가는 상영 컨텐츠 안내 키오스크 제작</li>
              </ul>
              등을 작업 하였습니다. ,<br />
              실제 노출 되는 곳이 인터넷이 불가능하거나 ,<br />
              간헐적으로 되는등 일반 적인 상황이 아닌곳도 있어서 <br />
              출장 작업을 하여 예상치 못한 이슈들을 관리 하는 노력이 추가적으로 필요 하였습니다.
            </p>
          </dd>
        </dl>
      </section>

      <h2>GS 칼텍스</h2>
      <img className={styles.mobileImg} src={image1} alt={'GS 칼텍스'} loading="lazy" decoding="async" />

      <h2>지하철 2호선 , 신분당선</h2>
      <img className={styles.mobileImg} src={image2} alt={'지하철 2호선 , 신분당선'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={image3} alt={'지하철 2호선 , 신분당선'} loading="lazy" decoding="async" />
      <img className={styles.mobileImg} src={image4} alt={'지하철 2호선 , 신분당선'} loading="lazy" decoding="async" />
    </div>
  );
};
