export const NAV_LINKS: { [key: string]: T_LinkItem } = {
  HOME: { url: '/', text: 'Home' },

  NAVERLAND: {
    url: '/naverland',
    text: '네이버 부동산 서비스',
    period: '2023.08 ~ 현재',
    skills: 'ES5, jQuery , Jindo',
  },

  INSURANCE: {
    url: '/insurance',
    text: '네이버 보험 청구 서비스',
    period: '2022.10 ~ 현재',
    skills: 'react , recoil , typescript',
  },

  CLOVA_OCR: {
    url: '/clovaocr',
    text: 'NAVER 클로바 OCR Builder',
    period: '2021.12 ~ 2022.07',
    skills: 'react , redux , typescript',
  },
  VLIVE_LIVE_MULTI: {
    url: '/vlivelive', //
    text: 'vLive live 플레이어 멀티뷰',
    period: '2021.07 ~ 2021.09',
    skills: 'Vanilla JS',
  },
  NAVER_ESPORTS_LIVE_PLAYER: {
    url: '/naveresportsplayer', //
    text: 'NAVER esports live 플레이어',
    period: '2020.12 ~ 2021.07',
    skills: 'Vanilla JS',
  },
  LINE_JP_POTAL: {
    url: '/linejppotal', //
    text: '일본 라인 모바일 포털검색',
    period: '2019.09 ~ 2020.07',
    skills: 'Vanilla JS',
  },
  VLIVE_LIVE_ULL: {
    url: '/vliveull', //
    text: 'vlive live 플레이어 ULL',
    period: '2018.09 ~ 2019.03',
    skills: 'Vanilla JS , dash.js , google dash 프로토콜 (mpd파일)',
  },
  HTML5_VOD_LIVE: {
    url: '/html5vodlive',
    text: '통합된 html5 vod , live 플레이어',
    period: '2016.07 ~ 2018.01',
    skills: 'Vanilla JS',
  },
  FLASH_RMC: {
    url: '/flashrmc',
    text: 'NAVER RMC 동영상 플레이어',
    period: '2012.09 ~ 2020.12',
    skills:
      'flash as 3.0  ,   flash cc , flash builder 7.0 ,   pure MVC  ,  RSL    (Run Time Shared Library) ,   HLS    (Http Live Streaming) ,   ttml    (국제 표준 자막 기능)  ,  vast    (국제 표준 광고 규약 )',
  },
  EBOOK_DOWNLOADER: {
    url: '/ebookdownloader',
    text: '온라인 ebook 다운로더 어플리케이션',
    period: '2013',
    skills: 'flash    air',
  },

  SIGNAGE_KIOSK: {
    url: '/signagekiosk',
    text: '사이니지 및 키오스크 작업',
    period: '2011 ~ 2015',
    skills: 'flash',
  },

  FLASH_ETC: {
    url: '/flashetc',
    text: '네이버의 플래시 작업들',
    period: '2011.05 ~2012.09',
    skills: 'flash as 2.0  3.0   ,   flash 모션 (타임라인 모션)   ,  flash air 어플리케이션 및 인증서   ,  flash builder',
  },

  HTML5_ETC: {
    url: '/html5etc',
    text: '네이버의 html 작업들',
    period: '2010.10 ~ 2011.05',
    skills: 'html , css  ',
  },

  OPENBASE: {
    url: '/openbase',
    text: 'OPENBASE 및 자회사 홈페이지 제작',
    period: '2008 ~ 2010',
    skills: '리눅스 cent os 서버 ,   mysql  ,  php  ,  flash as 2.0 , 3.0  ,  jQuery  ,  html , css',
  },

  OPENBASE_POS: {
    url: '/openbasepos',
    text: 'OPENBASE 사내 카페 POS 제작',
    period: '2008 ~ 2010',
    skills: 'java 1.6  ,  java swing ,   php ,   리눅스 서버  ,  mysql  ,  html , css ,   jQuery',
  },
};
export type T_LinkItem = {
  url: string;
  text: string;
  period?: string;
  skills?: string;
};
